@font-face {
    font-family: 'prophet';
    src: url('prophet_bold_italic-webfont.woff2') format('woff2'),
        url('prophet_bold_italic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'prophet';
    src: url('prophet_bold-webfont.woff2') format('woff2'),
        url('prophet_bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'prophet';
    src: url('prophet_extrabold_italic-webfont.woff2') format('woff2'),
        url('prophet_extrabold_italic-webfont.woff') format('woff');
    font-weight: 8000;
    font-style: italic;
}

@font-face {
    font-family: 'prophet';
    src: url('prophet_extrabold-webfont.woff2') format('woff2'),
        url('prophet_extrabold-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'prophet';
    src: url('prophet_light_italic-webfont.woff2') format('woff2'),
        url('prophet_light_italic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'prophet';
    src: url('prophet_light-webfont.woff2') format('woff2'),
        url('prophet_light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'prophet';
    src: url('prophet_medium_italic-webfont.woff2') format('woff2'),
        url('prophet_medium_italic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'prophet';
    src: url('prophet_medium-webfont.woff2') format('woff2'),
        url('prophet_medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'prophet';
    src: url('prophet_regular_italic-webfont.woff2') format('woff2'),
        url('prophet_regular_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'prophet';
    src: url('prophet_regular-webfont.woff2') format('woff2'),
        url('prophet_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'prophet';
    src: url('prophet_thin_italic-webfont.woff2') format('woff2'),
        url('prophet_thin_italic-webfont.woff') format('woff');
    font-weight: lighter;
    font-style: italic;
}

@font-face {
    font-family: 'prophet';
    src: url('prophet_thin-webfont.woff2') format('woff2'),
        url('prophet_thin-webfont.woff') format('woff');
    font-weight: lighter;
    font-style: normal;
}

@font-face {
    font-family: 'circular';
    src: url('circular-Book.woff2') format('woff2'),
        url('circular-Book.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'circular';
    src: url('circular-MediumItalic.woff2') format('woff2'),
        url('circular-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'circular';
    src: url('circular-Bold.woff2') format('woff2'),
        url('circular-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'circular';
    src: url('circular-Medium.woff2') format('woff2'),
        url('circular-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'circular';
    src: url('circular-BookItalic.woff2') format('woff2'),
        url('circular-BookItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'circular';
    src: url('circular-Black.woff2') format('woff2'),
        url('circular-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'circular';
    src: url('circular-BoldItalic.woff2') format('woff2'),
        url('circular-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'circular';
    src: url('circular-BlackItalic.woff2') format('woff2'),
        url('circular-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}